body {
  box-sizing: border-box;
  background-color: #050c24;
}

@font-face {
  font-family: "BeldgoRegular";
  src: url("/public/Beldgo-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.text {
  color: #d2dadf;
}

/* banner */
.banner {
  background-color: #050c24; /* Background color of the banner */
  margin-top: 99px; /* Height of the fixed navbar */
  padding-top: 40px;
  z-index: 900;
}

.banner h2 {
  font-size: 55px;
}

.banner-intro {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #5ee616;
  min-height: 80vh;
}

.banner-logo {
  width: 525px;
  max-width: 100%;
  height: auto;
}

.storm {
  background: linear-gradient(to right, #5ee616, #15b8a3);
  color: #050c24;
  border: 1px solid white;
}

.airdrop {
  background: linear-gradient(to right, #5ee616, #15b8a3);
  color: #050c24;
  border: 1px solid white;
}

.btn {
  font-family: "BeldgoRegular", sans-serif;
}

.ban {
  font-family: "BeldgoRegular", sans-serif;
}

.lis {
  color: #5fe616c5;
}



.total-supply {
  font-size: 25px;
  list-style-type: none;
}

.token-address {
  font-size: 1rem;
  list-style-type: none;
}

.no-style-link {
  color: inherit;
  text-decoration: none;
}

.no-style-link:hover {
  color: yellow;
}


.text-p {
  font-size: 20px;
}

/* Navbar.css */
.navbar {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.bg-transparent {
  background-color: transparent !important;
  backdrop-filter: blur(10px); /* For blur effect */
}

.bg-scrolled {
  background-color: rgba(
    55,
    61,
    80,
    0.8
  ) !important; /* Semi-transparent color #373D50 */
  backdrop-filter: blur(2px); /* Apply blur effect */
}

.nav-link {
  color: white;
}

.nav-link.active {
  color: #5ee616 !important; /* Active link text color */
  border-bottom: 2px solid #5ee616; /* Bottom border for active link */
}

.nav-link:hover {
  color: #5ee616 !important; /* Active link text color */
  border-bottom: 2px solid #5ee616; /* Bottom border for active link */
}

.nav-item {
  font-family: "BeldgoRegular", sans-serif;
}

.stake-button {
  background: linear-gradient(to right, #5ee616, #15b8a3);
  border-radius: 5px;
  color: #050c24;
  border: 1px solid white;
}

.stake-button:hover {
  background: #050c24;
  border-radius: 5px;
  color: white;
  border: 2px solid #5ee616;
}

.stake-small {
  display: none;
}

/* about section */
#about {
  text-align: center;
  padding: 5px;
  margin-top: 30px;
}

.about-title {
  font-family: "BeldgoRegular", sans-serif;
  font-size: 2rem; /* Adjust size as needed */
  font-weight: bold; /* Optional, adjust weight as needed */
  background: linear-gradient(45deg, #5ee616, #15b8a3); /* Gradient colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Make text color transparent so background shows through */
}

.text {
  /* Add any additional styling for the text here if needed */
}

#about img {
  width: 60%;
  border: 2px solid #5ee616;
  margin-top: 30px;
  padding: 20px;
}

/* incentive section */
.incent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  padding: 75px;
  padding-top: 30px;
}

.card {
  border: 2px solid rgba(128, 128, 128, 0.381);
  background: #050c24;
}

.card:hover {
  border: 2px solid #5ee616;
}

.card-title {
  background: linear-gradient(45deg, #5ee616, #15b8a3); /* Gradient colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Make text color transparent so background shows through */
}

.incent-top {
  margin-top: 110px;
}

.incent-image {
  width: 60px;
}

/* token economics section */

.contain {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically, if needed */
  width: 100%;
}

ul {
  list-style-type: none; /* Remove default list styling */
}

.centered-item {
  padding: 10px;
  border-bottom: 2px solid #5fe616c5;
  border-radius: 10px;
}

/* road map section */
.roadmap-container {
  float: left;
  width: 100%;
  padding: 50px 0;
  font-family: "Stag Sans Round", sans-serif;
  font-size: 16px;
}

.roadmap-auto {
  max-width: 950px;
  margin: 0 auto;
}

.roadmap-container .roadmap-h2 {
  background: url(../public/images/swap.png) center bottom no-repeat;
  background-size: 250px 3px;
  color: #159cdf;
  z-index: 1;
  font-family: "Stag Sans Round", sans-serif;
  font-size: 45px;
  line-height: 55px;
  font-weight: 500;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 6px;
  text-align: center;
}

.roadmap-container .roadmap-timeline {
  padding-top: 30px;
  max-width: 1000px;
  margin: 0 auto;
}

.roadmap-container .timeline-dot {
  position: absolute;
  left: 50%;
  z-index: 3;
  width: 8px;
  height: 8px;
  margin-left: -6px;
  border: 2px solid #1bacb3;
  border-radius: 6px;
  background-color: #1bacb3;
}

.roadmap-container .timeline-list {
  float: left;
  width: 100%;
  position: relative;
}

.roadmap-container .timeline-list .timeline-list-col {
  float: left;
  width: 50%;
  padding-top: 50px;
  padding-right: 0;
  padding-left: 0;
  position: relative;
  z-index: 3;
}

.roadmap-container .timeline-list .timeline-item-block {
  width: 350px;
  margin-bottom: 50px;
  float: left;
  text-align: left;
}

.roadmap-container .timeline-list .timeline-item-block17q4 {
  margin-top: 50px;
}

.roadmap-container .timeline-list .timeline-item-block18q2 {
  margin-top: 50px;
}

.roadmap-container .timeline-list .timeline-item-block .timeline-item-time {
  position: relative;
  width: 320px;
  min-height: 36px;
  border-bottom: 0 solid #1bacb3;
}

.roadmap-container .timeline-list .timeline-item-block .timeline-item-label {
  float: left;
  font-size: 24px;
  line-height: 26px;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  color: #222;
}

.roadmap-container .timeline-list .timeline-item-block .timeline-item-content {
  position: relative;
  left: 0;
  width: 280px;
  margin-top: 0;
  margin-right: 40px;
  float: left;
  width: 100%;
  font-size: 16px;
  line-height: 21px;
  color: #d2dadf;
  font-weight: 400;
  text-transform: none;
  border: 1px solid #ddd;
  border-top: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 0;
}

.roadmap-container
  .timeline-list
  .timeline-item-block
  .timeline-item-content
  p {
  background: url(../public/images/logo_icon.png) 0 2px no-repeat;
  margin-bottom: 10px;
  background-size: 16px 16px;
  padding-left: 24px;
}

.roadmap-container
  .timeline-list
  .timeline-item-block
  .timeline-item-content
  p.no-bullet {
  background: none;
  padding-left: 0;
}

.roadmap-container .timeline-list .timeline-col-right {
  padding-top: 150px;
}

.roadmap-container .timeline-list .timeline-col-right .timeline-item-block {
  float: right;
}

.roadmap-container .timeline-list .timeline-col-right .timeline-item-label {
  float: right;
  text-align: right;
}

.roadmap-container .timeline-list .timeline-col-right .timeline-item-content {
  text-align: left;
  margin-left: 0px;
  margin-right: 0;
}

.roadmap-container .timeline-list .timeline-list-vr {
  width: 0;
  height: 100%;
  border-left: 1px solid #1bacb3;
  border-right: 1px solid #1bacb3;
  position: absolute;
  left: 50%;
  margin-left: -1px;
  z-index: 2;
}

.roadmap-container .timeline-list .timeline-item-label-hr {
  float: left;
  width: 475px;
  height: 3px;
  background-color: #555;
  position: relative;
}

.roadmap-container .timeline-list .timeline-item-block .item-dot {
  left: auto;
  right: 0;
  bottom: -5px;
  margin-right: -6px;
  margin-left: 0;
}

.roadmap-container .timeline-list .timeline-col-right .item-dot {
  left: 0;
  right: auto;
  margin-right: 0;
  margin-left: -7px;
}

.roadmap-container .timeline-list .timeline-col-right .timeline-item-label-hr {
  margin-left: -125px;
}

.roadmap-container .timeline-list .timeline-item-block .timeline-item-label2 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
  padding: 7px 10px 6px 10px;
}

.roadmap-container
  .timeline-list
  .timeline-item-block-green
  .timeline-item-label2 {
  background-color: #78be20;
}

.roadmap-container
  .timeline-list
  .timeline-item-block-green
  .timeline-item-label-hr {
  background-color: #78be20;
}

.roadmap-container .timeline-list .timeline-item-block-green .item-dot {
  background-color: #78be20;
  border-color: #fff;
}

.roadmap-container
  .timeline-list
  .timeline-item-block-green2
  .timeline-item-label2 {
  background-color: #26a3aa;
}

.roadmap-container
  .timeline-list
  .timeline-item-block-green2
  .timeline-item-label-hr {
  background-color: #26a3aa;
}

.roadmap-container .timeline-list .timeline-item-block-green2 .item-dot {
  background-color: #26a3aa;
  border-color: #fff;
}

.roadmap-container
  .timeline-list
  .timeline-item-block-blue
  .timeline-item-label2 {
  background-color: #159cdf;
}

.roadmap-container
  .timeline-list
  .timeline-item-block-blue
  .timeline-item-label-hr {
  background-color: #159cdf;
}

.roadmap-container .timeline-list .timeline-item-block-blue .item-dot {
  background-color: #159cdf;
  border-color: #fff;
}

.roadmap-container
  .timeline-list
  .timeline-item-block-blue2
  .timeline-item-label2 {
  background-color: #00507f;
}

.roadmap-container
  .timeline-list
  .timeline-item-block-blue2
  .timeline-item-label-hr {
  background-color: #00507f;
}

.roadmap-container .timeline-list .timeline-item-block-blue2 .item-dot {
  background-color: #00507f;
  border-color: #fff;
}

.roadmap-container
  .timeline-list
  .timeline-item-block-violet
  .timeline-item-label2 {
  background-color: #890c58;
}

.roadmap-container
  .timeline-list
  .timeline-item-block-violet
  .timeline-item-label-hr {
  background-color: #890c58;
}

.roadmap-container .timeline-list .timeline-item-block-violet .item-dot {
  background-color: #890c58;
  border-color: #fff;
}

.roadmap-container .timeline-list .timeline-item-block-mobile {
  display: none;
}

@media screen and (max-width: 950px) {
  .roadmap-container {
    padding: 22px 0;
  }

  .spacer-block {
    margin-bottom: 0;
  }

  .roadmap-container .roadmap-h2 {
    font-size: 25px;
    line-height: 30px;
    background-size: 150px 3px;
  }

  .roadmap-container .timeline-list .timeline-list-vr {
    left: 20px;
  }

  .roadmap-container .timeline-dot {
    left: 20px;
  }

  .roadmap-container .roadmap-timeline {
    padding-top: 0;
  }

  .roadmap-container .timeline-list .timeline-list-col {
    float: none;
    width: auto;
    margin-left: 25px;
    padding-left: 25px;
    margin-right: 25px;
  }

  .roadmap-container .timeline-list .timeline-item-block-mobile {
    display: block;
  }

  .roadmap-container .timeline-list .timeline-item-block .timeline-item-time {
    width: auto;
  }

  .roadmap-container .timeline-list .timeline-item-label-hr {
    float: none;
    width: auto;
    margin-left: -30px;
  }

  .roadmap-container .timeline-list .timeline-item-block .timeline-item-label {
    float: none;
  }

  .roadmap-container .timeline-list .timeline-item-block .item-dot {
    left: 0;
    right: auto;
    margin-left: -6px;
    margin-right: 0;
  }

  .roadmap-container .timeline-list .timeline-item-block .timeline-item-label2 {
    width: auto;
  }

  .roadmap-container
    .timeline-list
    .timeline-item-block
    .timeline-item-content {
    float: none;
    width: auto;
    margin-right: 0;
  }

  .roadmap-container .timeline-list .timeline-col-right {
    display: none;
  }

  .roadmap-container .timeline-list .timeline-item-block {
    float: none;
    width: auto;
  }
}

/* network scetion */
li {
  list-style-type: none;
}

.networks {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 50px;
}

.networks div {
  border: 2px solid rgba(128, 128, 128, 0.381);
  padding: 10px;
  display: flex;
  align-items: center;
}

.networks div:hover {
  border: 2px solid #5ee616;
}

/* Social icons for footer and network*/
.glow:hover {
  box-shadow: 0 0 25px rgba(0, 255, 0, 1);
}

/* Footer section */
footer {
  background-color: #1e2230;
  color: white;
  position: relative;
  width: 100%;
  padding-top: 40px;
}

.co-cont {
  margin-right: 35px;
}
.containerr {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 100%;
  margin: 10px 40px;
  padding: auto 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.column {
  width: 20% 20% 20% 20% 20%; /* Adjust width as needed */
  padding: 0 0;
  min-width: 0;
}

.opp {
  padding: 0;
  margin: 0;
}

 .column a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  color: #5ee616;
  letter-spacing: 0.03em;
}

.footer-divider {
  height: 0.5px;
  background: #5ee616;
  margin-top: 40px;
}

.column a:hover,
.column.co-serve a:hover {
  color: #5ee616;
  transition: color 0.3s ease;
}

/* Token Allocation */

.table-container {
  width: 80%;
  margin: 20px auto;
  overflow-x: auto; /* Allows horizontal scrolling if needed */
  border-radius: 10px;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
}

.styled-table th,
.styled-table td {
  padding: 10px;
  text-align: center;
  color: #fff;
}

.styled-table thead {
  background-color: #379704;
  color: white;
}

.styled-table tbody tr:nth-child(odd) {
  background-color: #050c24;
}

.styled-table tbody tr:nth-child(even) {
  background-color: #379704; /* Light grey color for even rows */
}

/* Inc.css */
.inc-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create three equal columns */
  gap: 16px; /* Space between columns */
  padding: 75px;
}

.grid-item {
  background-color: #050c24;
  padding: 16px; /* Padding inside each item */
  border: 2px solid rgba(128, 128, 128, 0.381);
  border-radius: 10px;
}

.grid-item:hover {
  border: 2px solid #5ee616;
}


/* responsiveness small Pc */
@media screen and (max-width: 1024px) {
  .banner-intro {
    min-height: 65vh;
  }

  .banner-logo {
    width: 450px;
  }
}

/* responsiveness Tablet */
@media screen and (max-width: 768px) {
  .first {
    display: none;
  }
  .column {
    width: 100%;
    padding-bottom: 20px;
  }
  .co-cont {
    width: 100%;
  }
  .banner-image {
    display: none;
  }

  .banner-intro {
    grid-template-columns: 1fr;
    min-height: 70vh;
  }

  .stake-large {
    display: none;
  }
  .stake-small {
    display: flex;
  }

  #about img {
    width: 80%;
  }

  .incent {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .table-container {
    width: 100%;
    margin: 0;
  }

  .networks {
    grid-template-columns: 1fr 1fr;
    padding: 10px;
  }

  .styled-table th,
  .styled-table td {
    white-space: nowrap;
  }

  .inc-grid{
    grid-template-columns: 1fr;
    padding: 20px;
  }
}

/* responsiveness Mobile large */
@media screen and (max-width: 425px) {
  .banner-intro {
    min-height: 60vh;
  }

  .token-address {
    font-size: 0.7rem;
  }

  .text-p {
    font-size: 16px;
  }

  #about img {
    width: 90%;
  }
}


a{
  text-decoration: none;
}

/* responsiveness Mobile Medium */
@media screen and (max-width: 375px) {
  .total-supply {
    font-size: 16px;
  }

  .token-address {
    font-size: 0.8rem;
  }
  .text-p {
    font-size: 14px;
  }
}

/*  Others */
html {
  scroll-behavior: smooth;
}
